<template>
  <a-modal :width="500" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="visible"
    @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading" okText="提交审核">
    <a-form-model ref="ruleForm" :model="formData" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row v-if="pageType == 3">
        <a-col>
          <a-form-model-item ref="certificateType" label="证件种类" prop="certificateType" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select placeholder="请选择证件种类" v-model="formData.certificateType" option-label-prop="label">
              <a-select-option v-for="item in manuList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="pageType == 3">
        <a-col>
          <!-- 厂商、公司 -->
          <a-form-model-item ref="certificateType" label="证件种类" prop="certificateType" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select placeholder="请选择证件种类" v-model="formData.certificateType" option-label-prop="label">
              <a-select-option v-for="item in manuList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="pageType == 1">
        <a-col>
          <a-form-model-item ref="certificateType" label="证件种类" prop="certificateType" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <a-select placeholder="请选择证件种类" v-model="formData.certificateType" option-label-prop="label">
              <a-select-option v-for="item in categoryList" :key="item.id" :value="item.id" :label="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="pageType == 3">
        <a-col>
          <a-form-model-item required label="厂商名称" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-input placeholder="请输入厂商名称" v-model="formData.name"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col>
          <a-form-model-item required label="资质文件" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-upload name="file" :accept="'.pdf,.png,.jpg,.jpeg,pdf'" :multiple="false" v-if="!formData.fileUrl"
              :action="qiNiuAction" :data="postData" @change="handleChange">
              <a-button>
                <a-icon type="upload" /> 上传文件
              </a-button>
            </a-upload>
            <div v-else>
              <a :href="formData.fileUrl" style="margin-left: 5px;" target="_blank">预览文件</a>
              <a @click="removeFile" style="margin-left: 5px;color:red;">删除</a>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-form-model-item label="有效日期开始" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker v-model="dateStartTime" @change="onStartChange" />
        </a-form-model-item>
      </a-row>
      <a-row>
        <a-form-model-item label="有效日期结束" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
          <a-date-picker style="margin-right: 10px;" v-if="!langTime" v-model="dateEndTime" @change="onEndChange" />
          <a-checkbox v-model="langTime" @change="onLangTime">长期有效</a-checkbox>
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import { selectCitymixins } from '@/mixin/selectCity'
import { publicMixin } from '@/mixin/public';
export default {
  mixins: [selectCitymixins, publicMixin],
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    confirmLoading: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    pageType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      moment,
      rules: {
        certificateType: [{ required: true, message: '请选择证件种类', trigger: 'change' }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      fileList: [],
      qiNiuAction: 'https://upload-z1.qiniup.com',
      qiNiuUploadData: {
        key: '',
        token: ''
      },
      getQiniuTokenIng: false,
      postData: {
        token: ''
      },
      dateStartTime: "",
      dateEndTime: "",
      langTime: false,//长期有效
      manuList: [{ id: 301, name: '生产商营业执照' }, { id: 302, name: '食品生产许可证' }],
      categoryList: [{ id: 101, name: '营业执照' }, { id: 102, name: '一般纳税人证明' }, { id: 103, name: '基本开户信息表' }, { id: 104, name: '食品经营许可证' }]
    }
  },
  mounted() {
    this.getQiniuToken()
    if (this.formData.id) {
      let startDate = moment(this.formData.startTime)
      let endDate = moment(this.formData.endTime)
      this.dateValue = [startDate, endDate]
      this.dateStartTime = startDate
      this.dateEndTime = endDate
      if (this.formData.endTime == '4070880000000') {
        this.langTime = true;
      }
    }
  },
  methods: {

    onStartChange(date, dateString) {
      this.dateStartTime = date;
      this.formData.startTime = dateString + ' 00:00:00'
    },
    onEndChange(date, dateString) {
      this.dateEndTime = date;
      this.formData.endTime = dateString + ' 23:59:59'
    },
    onLangTime(e) {
      this.langTime = e.target.checked
    },
    dateChange(date, dateString) {
      this.dateValue = date;
      this.formData.startTime = dateString[0] + ' 00:00:00'
      this.formData.endTime = dateString[1] + ' 23:59:59'
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('上传文件类型有误!');
      }
      const isLt30M = file.size / 1024 / 1024 < 30;
      if (!isLt30M) {
        this.$message.error('Image must be smaller than 30MB!');
      }
      return isJpgOrPng && isLt30M;
    },
    handleChange(info) {
      const { fileList } = info;
      if (fileList.length > 0 && fileList[0].status === 'done') {
        console.log('上传成功:', fileList[0].response);
        this.$set(this.formData, 'fileUrl', fileList[0].response.url)
      }
    },
    getQiniuToken() {
      if (!this.getQiniuTokenIng) {
        this.getQiniuTokenIng = true
        this.loading = true
        this.loadingText = 'token加载中'
        request(process.env.VUE_APP_API_BASE_URL + 'srm/qiniu/token/yjhp-hb', 'get').then((res) => {
          if (res.data.code == 200) {
            this.postData.token = res.data.data
            // this.postData.key = 'sfa/upload' + Math.floor(Math.random() * 100) + new Date().valueOf()
            this.getQiniuTokenIng = false
          }
        })
      }
    },
    onChange(value) {
      console.log(value);
    },
    removeFile() {
      this.formData.fileUrl = ""
      this.fileList = []
    },
    handleCancel() {
      // 处理取消操作
      this.$emit('cancel');
    },
    handleSubmit() {
      // 处理表单提交
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (!this.formData.fileUrl) {
            this.$message.error('请上传资质文件！');
            return false;
          }
          if (!this.formData.certificateType) {
            this.$message.error('请选择证件种类');
            return false;
          }
          // 长期有效
          if (this.langTime) {
            this.formData.endTime = "2099-1-1 00:00:00"
          }
          if (!this.formData.startTime || !this.formData.endTime) {
            this.$message.error('请选择有效期');
            return false;
          }
          if (this.pageType == 3 && !this.formData.name) {
            this.$message.error('请输入厂商名称！');
            return false;
          }

          this.confirmLoading = true;

          let obj = {
            "qualificationFileList": [this.formData]
          }
          console.log(obj, 'obj')
          this.$emit('submit', obj)
          this.confirmLoading = true;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>
<style>
.title-text {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}
</style>
